import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import ArrowRightIcon from '../../assets/icons/arrow-right.svg'
import '../../pages/building-apps-crash-course/building-apps-crash-course.scss'

const NextLesson = ({ title, description, slug }) => {
  return (
    <section className="bacc_next-lesson u-flex u-flex-col u-items-center u-text-center bacc_main-section">
      <div className="u-font-bold bacc_next-lesson-banner">Next lesson</div>
      <h2 className="u-font-bold bacc_section-title">{title}</h2>
      <p>{description}</p>
      <Link to={slug}>
        <button className="u-font-medium bacc_btn-blue">
          Go to next lesson <ArrowRightIcon />
        </button>
      </Link>
    </section>
  )
}

NextLesson.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
}

export default NextLesson
