import React, { Fragment } from 'react'
import Layout from '../../layout'
import Accordion from '../../components/BuildingAppsCrashCourse/Accordion'
import ConsoleBanner from '../../components/BuildingAppsCrashCourse/ConsoleBanner'
import LessonSection from '../../components/BuildingAppsCrashCourse/LessonSection'
import NextLesson from '../../components/BuildingAppsCrashCourse/NextLesson'
import LessonResources from '../../components/BuildingAppsCrashCourse/LessonResources'
import LessonHeader from '../../components/BuildingAppsCrashCourse/LessonHeader'
import YoutubeVideo from '../../components/BuildingAppsCrashCourse/YoutubeVideo'
import { transcript } from '../../components/BuildingAppsCrashCourse/transcript'
import { SEO } from '../../components'
import BuildingAppsCrashCourseOgImage from '../../assets/images/building-apps-crash-course-og-image.png'
import './building-apps-crash-course.scss'

const DeployYourApp = () => (
  <Layout className="bacc_layout-styles" isCourseSite>
    <SEO
      title="Deploy your app"
      description="Deploy your app to production using popular services: Heroku, Firebase, or Netlify, and complete the final app configuration in the Developer Console."
      image={BuildingAppsCrashCourseOgImage}
    />
    <div className="bacc_main-wrapper">
      <LessonHeader
        title="3. Deploy your app"
        summary={
          <Fragment>
            <p>
              Welcome back to the course. In this episode, you’ll deploy your
              app for production using one of the three most popular services:{' '}
              <a
                href="https://www.heroku.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Heroku
              </a>
              ,{' '}
              <a
                href="https://firebase.google.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Firebase
              </a>
              , or{' '}
              <a
                href="https://www.netlify.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Netlify
              </a>
              . You’ll also complete the configuration of your app in the
              Developer Console so you can submit it for the app review.
            </p>
            <p>Let’s get down to it!</p>
          </Fragment>
        }
      />
      <YoutubeVideo id="lGPQkIF34NY" eventLabel="deploy your app" />
      <section className="bacc_main-section">
        <h3 className="u-font-bold bacc_section-title">Video summary</h3>
        <div>
          {transcript.deployYourApp.map((item, index) => (
            <Accordion
              key={index}
              num={index}
              title={item.title}
              content={item.content}
            />
          ))}
        </div>
      </section>
      <LessonResources
        transcriptSlug="building-apps-crash-course-deploy-your-app.pdf"
        isRepo
        articleTitle="Hosting your app for production"
        articleSlug="updates/hosting-apps-for-production"
        pageName="Deploy your app"
      />
      <NextLesson
        title="4. Excel at the app review"
        description="You’ll learn all about the app review process and how to 
        pass it, so that you can publish your app on the Marketplace in no time. "
        slug="/building-apps-crash-course/app-review"
      />
      <LessonSection />
    </div>
    <ConsoleBanner />
  </Layout>
)

export default DeployYourApp
